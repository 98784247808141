.CardInfoCliente > .ant-card-body {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  margin-left: -12px !important;
}

.CardInfoCliente {
  border-color: #81973a !important;
  margin-top: -0.8em !important;
  height: auto !important;
}

.CardMediciones > .ant-card-body {
  padding-top: 1em !important;
  padding-bottom: 0em !important;
}

.CardMediciones {
  border-color: #81973a !important;
  margin-top: 2% !important;
  height: auto !important;
}

.CardMonitoreo > .ant-card-body {
  padding-top: 1em !important;
  padding-bottom: 0em !important;
  padding-left: 6em;
}

.CardMonitoreo {
  border-color: #81973a !important;
  margin-top: 2% !important;
  height: auto !important;
}


.TabTanque.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 1px solid #ffffff !important;
}

.TabTanque .ant-tabs-content-holder {
  /* padding-top: 8px !important;
  padding-left: 12px !important;
  padding-right: 8px !important;
  border: 1px solid #81973a !important;
  margin-top: -2% !important; */
  height: 0px !important;
}

.botonAlerta{
  border-radius: 50% !important;
  width: 28%;
  height: 10%;
}
