.ant-checkbox-inner {
  background-color: #81973a !important;
  border-color: #81973a !important;
}
.LinkOC {
  color: #81973a !important;
  font-size: 80% !important;
}
.LinkOC:hover {
  color: #81973a !important;
}
.swal2-confirm.swal2-styled {
  background-color: #81973a !important;
}
.swal2-icon.swal2-info.swal2-icon-show {
  border-color: #81973a !important;
}

.swal2-validation-message.swal2-validation-message::before{
  background-color: #81973a !important;
}
