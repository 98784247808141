.CardFacturacion {
  margin-top: 1% !important;
}

.CardFacturacion > .ant-card-body {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.FechaFac .ant-picker-input > input{
  font-weight: bold !important;
  width: 100%;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td {
  padding-right:  5px !important;
}