.ant-dropdown-trigger {
  color: white !important;
}
.ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
  background-color: #81973a !important;
}
.MenuSalir {
  background-color: #81973a !important;
  color: white !important;
}

.BotonPanelNotificaciones {
  background-color: #81973a !important;
  color: white !important;
  border-color: #81973a !important;
  float: right;
}

.ant-card-head {
  border-bottom-color: darkgreen !important;
  border-radius: 25px;
  /* line-height: 0.5em !important; */
}
