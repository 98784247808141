.ImagenTanque {
  background-image: url(../../../assets/tanque.png);
  height: 100%;
  width: auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.TabTanque.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 1px solid #ffffff !important;
}

.TabTanque .ant-tabs-content-holder {
  /* padding-top: 8px !important;
  padding-left: 12px !important;
  padding-right: 8px !important;
  border: 1px solid #81973a !important;
  margin-top: -2% !important; */
  height: 0px !important;
}

.ant-table.ant-table-small.ant-table-empty.ant-table-scroll-horizontal
  .ant-table-thead
  > tr
  > th {
  line-height: 0.8em !important;
  background-color: #81973a !important;
  color: #fff !important;
}

.CardInventario {
  background-color: #81973a !important;
  color: #fff !important;
}

.CardFormularioTanque > .ant-card-body {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.CardFormularioTanque {
  margin-top: -2% !important;
  border-color: #81973a !important;
  height: 80% !important;
}
