body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #81973a;
  line-height: 48px;
  text-align: center;
  background: #fff;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.anticon.anticon-left {
  font-size: 20px;
  font-weight: bold !important;
}

.anticon.anticon-right {
  font-size: 20px;
  font-weight: bold !important;
}

::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(
    #81973a 100%,
    #81973a 100%
  );
  border-radius: 100px;
}

.BotonGenerarPDF {
  background-color: #81973a !important;
  color: white !important;
  border-radius: 5px !important;
  border-color: #e6e8eb !important;
}

.site-layout {
  background-color: #fff !important;
}
