/* Atacar la clase de tab activo */
.ant-tabs-tab.ant-tabs-tab-active {
  background-color: #81973a !important;
}
.ant-tabs-tab.ant-tabs-tab-active > div {
  color: #fff !important;
}

/* Atacar la clase de tab inactivo */
.ant-tabs-tab {
  color: #000 !important;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: #000 !important;
}
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #000 !important;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 1px solid #d1d3d4 !important;
}

/* Clase de boton de semana actual en inventario */
.ant-btn.BotonAhora.ant-btn-sm {
  background-color: #81973a !important;
  color: #fff !important;
}
.ant-btn.BotonAhora.ant-btn-sm:hover,
.ant-btn.BotonAhora.ant-btn-sm:focus {
  border-color: #81973a !important;
}

.anticon.anticon-zoom-in {
  color: #81973a !important;
}

.anticon.anticon-zoom-out {
  color: #81973a !important;
}

.ant-table.ant-table-small.ant-table-empty.ant-table-has-fix-right
  .ant-table-thead
  > tr
  > th {
  background-color: #81973a !important;
  color: #fff !important;
}
