.ant-switch.ant-switch-checked {
  background-color: #81973a !important;
}
.anticon.anticon-edit {
  color: #81973a !important;
}
.anticon.anticon-edit:hover {
  font-size: 120%;
  color: #81973a !important;
}
.ant-input:hover {
  border-color: #81973a !important;
}
.ant-input:focus {
  border-color: #81973a !important;
}
.BotonBuscar {
  width: 90;
  background-color: #81973a !important;
  border-color: #81973a !important;
}
.BotonRestablecer {
  color: #81973a !important;
  border-color: #81973a !important;
}
.ant-pagination-item a {
  color: #81973a !important;
}
.ant-pagination-item:hover {
  border-color: #81973a !important;
}
.ant-pagination-item.ant-pagination-item-active {
  border-color: #81973a !important;
}
.ant-pagination-options:hover {
  border-color: #81973a !important;
}
.botonAgregarUsuario {
  background-color: #81973a !important;
  color: white !important;
  border-radius: 5px !important;
  border-color: #e6e8eb !important;
  margin-bottom: 1% !important;
  width: 100% !important;
  float: right !important;
}

.botonActualizarUsuario {
  background-color: #81973a !important;
  color: white !important;
  border-radius: 5px;
  border-color: #81973a !important;
  margin-bottom: 1% !important;
  width: 100% !important;
}
.swal2-icon.swal2-question {
  border-color: #81973a !important;
}
.swal2-icon-content {
  color: #81973a !important;
}
.ant-modal-title {
  text-align: center !important;
  font-weight: bold !important;
}
.iconoBuscar {
  margin-left: -8px !important;
  color: #fff !important;
}
.ant-table-filter-trigger-container:hover {
  background-color: #81973a !important;
}
.ant-table-filter-trigger-container:focus {
  background-color: #81973a !important;
}
.ant-table-filter-trigger.ant-dropdown-trigger.ant-dropdown-open {
  background-color: #81973a !important;
}

.ant-table-filter-trigger-container-open,
.ant-table-filter-trigger-container:hover,
.ant-table-thead
  th.ant-table-column-has-sorters:hover
  .ant-table-filter-trigger-container:hover {
  background: #81973a !important;
}

.anticon.anticon-filter {
  color: #fff !important;
}
.ant-dropdown-menu.ant-dropdown-menu-without-submenu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
  background-color: white !important;
}
.ant-btn.ant-btn-link.ant-btn-sm {
  color: #81973a;
}
.ant-btn.ant-btn-primary.ant-btn-sm {
  background-color: #81973a !important;
  color: white;
  border-color: #81973a !important;
}
.ant-table.ant-table-ping-right.ant-table-fixed-header.ant-table-fixed-column.ant-table-scroll-horizontal.ant-table-has-fix-right
  .ant-table-thead
  > tr
  > th {
  line-height: 0.4em !important;
  background-color: #81973a !important;
  color: #fff !important;
}

.ant-table.ant-table-ping-left.ant-table-fixed-header.ant-table-fixed-column.ant-table-scroll-horizontal.ant-table-has-fix-right
  .ant-table-thead
  > tr
  > th {
  line-height: 0.4em !important;
  background-color: #81973a !important;
  color: #fff !important;
}
