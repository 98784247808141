.botonActualizarConsumo {
  background-color: #81973a !important;
  color: white !important;
  border-radius: 5px;
  border-color: #81973a !important;
  margin-bottom: 1% !important;
  width: 100% !important;
}
.botonAgregarConsumo {
  background-color: #81973a !important;
  color: white !important;
  border-radius: 5px;
  border-color: #81973a !important;
  margin-bottom: 1% !important;
  width: 100% !important;
}
