@media (min-width: 360px) {
  .Content {
    margin: 15% 2% 0 2%;
  }
}
@media (max-width: 390px) {
  .Content {
    margin: 18% 2% 0 2%;
  }
}
@media (min-width: 576px) {
  .Content {
    margin: 9% 2% 0 2%;
  }
}

@media (min-width: 768px) {
  .Content {
    margin: 7% 2% 0 2%;
  }
}

@media (min-width: 992px) {
  .Content {
    margin: 5% 2% 0 2%;
  }
}

@media (min-width: 1200px) {
  .Content {
    margin: 3.5% 2% 0 2%;
  }
}
