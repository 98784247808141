.TablaNotificaciones .ant-table-small .ant-table-thead > tr > th {
  line-height: 0.5em !important;
  background-color: #81973a !important;
  color: #fff !important;
  font-weight: normal;
}
.TablaNotificaciones .ant-table-small .ant-table-tbody > tr > td {
  line-height: 2em !important;
}

.BotonVerMas {
  margin-top: -10%;
  color: #81973a !important;
}

.BotonVerMas:hover {
  font-size: 15px !important;
  color: #81973a !important;
}

.BotonVerMasAlert {
  color: #9e2727 !important;
}

.BotonVerMasAlert:hover {
  font-size: 15px !important;
  color: #9e2727 !important;
}

.FechaFac .ant-picker-input > input{
  font-weight: bold !important;
}

.ant-form-item-control-input-content > .ant-picker {
  height: 1.6em !important;
  width: 100%;
}

