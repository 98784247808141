.botonActualizarProveedor {
  background-color: #81973a !important;
  color: white !important;
  border-radius: 5px;
  border-color: #81973a !important;
  margin-bottom: 1% !important;
  width: 100% !important;
}
.botonAgregarProveedor {
  background-color: #81973a !important;
  color: white !important;
  border-radius: 5px !important;
  border-color: #e6e8eb !important;
  margin-bottom: 1% !important;
  width: 100% !important;
  float: right !important;
}

.ant-table .ant-table-thead > tr > th {
  line-height: 0.4em !important;
  background-color: #81973a !important;
  color: #fff !important;
}
