.ant-menu-item {
  background-color: #fff;
  color: gray !important;
}
.ant-menu-item:hover {
  background-color: #81973a !important;
  color: white !important;
}
.ant-menu-item.ant-menu-item-selected {
  background-color: #81973a !important;
}

a {
  color: gray !important;
}
a:hover {
  color: white !important;
}
.ant-menu-item.ant-menu-item-selected a {
  color: white !important;
}

/* .ant-menu-item.ant-menu-item-selected {
  color: white !important;
} */

.ant-menu.ant-menu-sub.ant-menu-inline {
  background-color: #fff !important;
}

.ant-menu-submenu-title {
  color: gray !important;
}

.ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected {
  color: white !important;
}

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-active > div {
  color: #81973a !important;
}

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected
  > div {
  color: #81973a !important;
}

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open > div {
  color: #81973a !important;
}

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-selected > div {
  color: #81973a !important;
}

.ant-menu {
  background: #fff !important;
}

.ant-menu-submenu-vertical
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after {
  background-image: linear-gradient(to right, #81973a, #81973a) !important;
}
