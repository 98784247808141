/* row data */
.ant-table-tbody > tr > td {
  padding: 0px 0px 0px 8px !important;
}

.BotonVerMas {
  color: #81973a !important;
}

.BotonVerMas:hover {
  font-size: 15px !important;
  color: #81973a !important;
}

.ant-picker-cell-inner :visited {
  background-color: #81973a !important;
}

.ant-spin-dot-item {
  background-color: #81973a !important;
}

.TablaCE .ant-table-small .ant-table-thead > tr > th {
  line-height: 0.7em !important;
  background-color: #81973a !important;
  color: #fff !important;
  font-weight: normal !important;
}

.TablaCE .ant-table-small .ant-table-body {
  font-size: 13px !important;
}

.TablaCE tr:last-child {
  font-weight: bold;
}

.TablaCE2 .ant-table-small .ant-table-thead > tr > th {
  line-height: 0.7em !important;
  background-color: #81973a !important;
  color: #fff !important;
  font-weight: normal !important;
}

.TablaCE2 .ant-table-small .ant-table-body {
  font-size: 13px !important;
}

.TablaCE2 tr:last-child {
  font-weight: bold;
}

.TablaCE2 .ant-table-cell {
  padding-right: 7px !important;
}

.ant-table.ant-table-small.ant-table-ping-right.ant-table-fixed-header.ant-table-scroll-horizontal
  .ant-table-thead
  > tr
  > th {
  line-height: 0.8em !important;
  background-color: #81973a !important;
  color: #fff !important;
  font-weight: normal !important;
}

.ant-table.ant-table-small.ant-table-ping-left.ant-table-fixed-header.ant-table-scroll-horizontal
  .ant-table-thead
  > tr
  > th {
  line-height: 0.8em !important;
  background-color: #81973a  !important;
  color: #fff !important;
}

.TabCE.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 1px solid #ffffff !important;
}
.TabCE .ant-tabs-content-holder {
  padding-top: 8px !important;
  padding-left: 12px !important;
  padding-right: 8px !important;
  padding-bottom: 8px !important;
  border: 1px solid #81973a !important;
  margin-top: -2% !important;
}

 .TabCE {
  margin-left: 3% !important;
}
