.site-collapse-custom-collapse .site-collapse-custom-panel {
  background: #f7f7f7;
  border-radius: 4px;
  margin-bottom: 2%;
  border: 0px;
  overflow: hidden;
}
.ant-collapse-header {
  background-color: #81973a;
  color: white !important;
  padding: 1% !important;
}
.botonGuardarUsuario {
  background-color: #81973a !important;
  color: white !important;
  border-radius: 5px;
  border-color: #81973a !important;
  margin-bottom: 1% !important;
  width: 100% !important;
  float: right !important;
}
.botonCancelarUsuarios {
  background-color: #cccec7 !important;
  color: white !important;
  border-radius: 5px;
  border-color: #cccec7 !important;
  margin-bottom: 1% !important;
  width: 100% !important;
  float: right !important;
}
.botonAgregarEmpresa {
  background-color: #81973a !important;
  color: white !important;
  border-radius: 5px;
  border-color: #81973a !important;
  margin-bottom: 1% !important;
  width: 100% !important;
}

.ant-select-selector:hover {
  border-color: #81973a !important;
}
.ant-input-affix-wrapper:hover {
  border-color: #81973a !important;
}
.ant-input-password.ant-input-affix-wrapper:hover {
  border-color: #81973a !important;
}
.ant-input-password.ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
  border-color: #81973a !important;
}

.ant-modal-header {
  background-color: #81973a !important;
}
.ant-modal-title {
  color: white !important;
  font-weight: 500 !important;
}
.ant-modal-close-x {
  color: white !important;
}
